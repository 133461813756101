<template>
    <CustomLoader v-if="pageLoading" />
    <div v-else>
        <div class="card card-padding">
            <el-form
                :model="createCustomReportForm"
                ref="createCustomReportFormRef"
                :rules="createCustomReportFormRules"
                class=" border-0 col-lg-10 m-auto px-xl-20 pt-5 pb-10">
                <div class="d-sm-flex justify-content-between gap-5">
                    <div class="w-sm-50">
                        <el-form-item class="mb-15 grey-field" prop="bank_ids">
                            <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Bank") }}</label>
                            <div class="cursor-pointer position-relative z-index-1">
                                <div @click="dialogBanksVisible = true" class="select-popup"></div>
                                <el-select
                                    class="position-relative"
                                    v-model="createCustomReportForm.bank_ids"
                                    :placeholder="$t('Select Bank')"
                                    size="large"
                                    collapse-tags
                                >
                                    <el-option
                                        v-for="item in banks"
                                        :key="item"
                                        :value="item.id"
                                        :label="item.name"
                                    >{{ item.name }}</el-option>
                                </el-select>
                            </div>
                            <div v-if="dialogBanksVisible">
                                <SelectModal
                                    :popupVisible="dialogBanksVisible"
                                    :dataForSelect="banks"
                                    :title="$t('Select Bank')"
                                    :subtitle="$t('Select Bank you want reports on')"
                                    :placeholder="$t('Search Bank')"
                                    :dataForUpdate="createCustomReportForm.bank_ids"
                                    @handle-close="dialogBanksVisible = false"
                                    @handle-confirm="handleConfirmBanks"
                                />
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="d-sm-flex justify-content-between gap-5">
                    <div class="w-sm-50">
                        <el-form-item class="mb-15 grey-field" prop="countries">
                            <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Countries") }}</label>
                            <div class="cursor-pointer position-relative z-index-1">
                                <div @click="dialogCountriesVisible = true" class="select-popup"></div>
                                <el-select
                                    class="position-relative"
                                    style="z-index: -1"
                                    disabled
                                    v-model="createCustomReportForm.countries"
                                    multiple
                                    collapse-tags
                                    @click="dialogCountriesVisible = true"
                                    :placeholder="$t('All Product Areas')">
                                </el-select>
                            </div>
                            <div v-if="dialogCountriesVisible">
                                <SelectModal
                                    :popupVisible="dialogCountriesVisible"
                                    :dataForSelect="countries"
                                    :dataForUpdate="createCustomReportForm.countries"
                                    :selfValue="true"
                                    multiple="true"
                                    :title="$t('Select Countries')"
                                    :subtitle="$t('Select Countries you want reports on')"
                                    :placeholder="$t('Search Countries')"
                                    @handle-close="dialogCountriesVisible = false"
                                    @handle-confirm="handleConfirmCountries"
                                />
                            </div>
                        </el-form-item>
                        <el-form-item class="mb-15 grey-field" prop="time_period">
                            <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Time Period") }}</label>
                            <DateRangePicker
                                v-model="createCustomReportForm.time_period"
                                :all-time-selection-value="businessCreatedDate"
                            />
                        </el-form-item>
                    </div>
                    <div class="w-sm-50">
                        <el-form-item class="mb-15 grey-field" prop="product_areas">
                            <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Product Areas") }}</label>
                            <div class="cursor-pointer position-relative z-index-1">
                                <div @click="dialogProductAreaVisible = true" class="select-popup"></div>
                                <el-select
                                    class="position-relative"
                                    style="z-index: -1"
                                    disabled
                                    v-model="createCustomReportForm.product_areas"
                                    multiple
                                    collapse-tags
                                    @click="dialogProductAreaVisible = true"
                                    :placeholder="$t('All Product Areas')">
                                </el-select>
                            </div>
                            <div v-if="dialogProductAreaVisible">
                                <SelectModal
                                    :popupVisible="dialogProductAreaVisible"
                                    :dataForSelect="product_areas"
                                    :dataForUpdate="createCustomReportForm.product_areas"
                                    :selfValue="true"
                                    multiple="true"
                                    :title="$t('Select Product Areas')"
                                    :subtitle="$t('Select Product Areas you want reports on')"
                                    :placeholder="$t('Search Product Areas')"
                                    @handle-close="dialogProductAreaVisible = false"
                                    @handle-confirm="handleConfirmProductArea"
                                />
                            </div>
                        </el-form-item>
                        <el-form-item class="mb-15 grey-field" prop="comparison_time_period">
                            <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Compared Time Period") }}</label>
                            <DateRangePicker
                                v-model="createCustomReportForm.comparison_time_period"
                                :all-time-selection-value="businessCreatedDate"
                            />
                        </el-form-item>
                    </div>
                </div>
                <hr />
                <div class="checkboxes-wrapper">
                    <div class="checkboxes-wrapper__item">
                        <div class="checkboxes-wrapper__item--col">
                            <div class="my-4 d-flex justify-content-between align-items-center w-100">
                                <label class="fw-bold fs-4 mb-0">{{ $t("Include Executive Summary") }}</label>
                                <el-form-item class="mb-0 ">
                                    <el-switch v-model="createCustomReportForm.is_executive_summary_included" />
                                </el-form-item>
                            </div>
                        </div>
                        <div class="checkboxes-wrapper__item--col">
                            <div class="my-4 d-flex justify-content-between align-items-center w-100">
                                <label class="fw-bold fs-4 mb-0">{{ $t("Include Comments") }}</label>
                                <el-form-item class="mb-0 ">
                                    <el-switch v-model="createCustomReportForm.are_comments_included" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="checkboxes-wrapper__item">
                        <div class="checkboxes-wrapper__item--col">
                            <div class="my-4 d-flex justify-content-between align-items-center w-100">
                                <label class="fw-bold fs-4 mb-0">{{ $t("Include Detailed View") }}</label>
                                <el-form-item class="mb-0 ">
                                    <el-switch v-model="createCustomReportForm.is_detailed_view_included" />
                                </el-form-item>
                            </div>
                        </div>
                        <div v-show="createCustomReportForm.are_comments_included" class="checkboxes-wrapper__item--col">
                            <div class="my-4 d-flex justify-content-between align-items-center w-100">
                                <label class="fw-bold fs-4 mb-0">{{ $t("Hide Full Name") }}</label>
                                <el-form-item class="mb-0 ">
                                    <el-switch v-model="createCustomReportForm.is_full_name_hided" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="checkboxes-wrapper__item">
                        <div class="checkboxes-wrapper__item--col">
                            <div class="my-4 d-flex justify-content-between align-items-center w-100">
                                <label class="fw-bold fs-4 mb-0">{{ $t("Include Custom Questions") }}</label>
                                <el-form-item class="mb-0 ">
                                    <el-switch v-model="createCustomReportForm.are_custom_questions_included" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </el-form>
            <button class="btn main-btn d-block mx-auto w-xl-25" :data-kt-indicator="buttonIndicator"
                    :disabled="buttonIndicator === 'on'" @click="generateReport">
                <span class="indicator-label">{{ $t("Generate Report") }}</span>

                <span class="indicator-progress">
        {{ $t("pleaseWait") }}
        <span
            class="spinner-border spinner-border-sm align-middle ms-2"
        ></span>
      </span>
            </button>
        </div>

        <div class="card card-padding arriving-soon-card mt-5" :class="{'show-arriving': showSuccessMessage}">
            <div class="arriving-soon-card__content">
                <div class="arriving-soon-card__img">
                    <img src="/media/buying/icons/undraw_data_processing_yrrv.svg" alt="">
                    <span>{{ $t("Arriving Soon...") }}</span>
                </div>
                <div class="arriving-soon-card__text">
                    <h4>{{ $t("We are now generating your report. Please wait a few minutes.") }}</h4>
                    <p>
                        {{ $t("Generated Report will be sent on") }}
                        <b>{{ userInfo.email }}</b>
                        {{ $t("as a link once done.") }}
                    </p>
                </div>
            </div>
            <div class="arriving-soon-card__hide">
                <button @click="showSuccessMessage = false">{{ $t("Hide") }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";
import store from "@/store";
import moment from "moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DateRangePicker from "@/buying-teams/shared-components/date-time/DateRangePicker";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
    name: "CustomReports",

    components: {
        CustomLoader,
        DateRangePicker,
        SelectModal
    },

    props: [],

    emits: [],
    computed: {
        product_areas() {
            return store.getters.businessProductAreas;
        },
        banks() {
            return store.getters.businessBanks;
        },
        countries() {
            return store.getters.businessCountries;
        },
        businessCreateDate() {
            return store.getters.business.created_at;
        },
        userInfo() {
            return store.getters.currentUser;
        },
        businessCreatedDate() {
            const businessData = store.getters.business;
            return businessData.created_at
                ? { start_date: businessData.created_at, end_date: new Date() }
                : { start_date: "", end_date: "" };
        }
    },

    watch: {
        'createCustomReportForm.is_executive_summary_included': {
            handler(newVal) {
                if (!newVal && !this.createCustomReportForm.is_detailed_view_included) {
                    this.createCustomReportForm.is_detailed_view_included = true;
                }
            }
        },
        'createCustomReportForm.is_detailed_view_included': {
            handler(newVal) {
                if (!newVal && !this.createCustomReportForm.is_executive_summary_included) {
                    this.createCustomReportForm.is_executive_summary_included = true;
                }
            }
        }
    },

    data() {
        let validateDateTime = (rule, value, callback) => {
            if (!value) return callback(new Error(rule.message));
            if (!value.start_date || !value.end_date) {
                return callback(new Error(rule.message));
            }
            return callback();
        };
        return {
            pageLoading: true,
            buttonIndicator: "off",
            dialogBanksVisible: false,
            dialogProductAreaVisible: false,
            dialogCountriesVisible: false,
            showSuccessMessage: false,
            createCustomReportForm: {
                time_period: {},
                comparison_time_period: {},
                bank_ids: "",
                countries: [],
                product_areas: "",
                are_custom_questions_included: false,
                are_comments_included: true,
                is_full_name_hided: false,
                is_executive_summary_included: true,
                is_detailed_view_included: true
            },
            createCustomReportFormRules: {
                bank_ids: [
                    { required: true, message: this.$t("Please select Bank"), trigger: "change" }
                ],
                countries: [
                    { required: true, message: this.$t("Please select Countries"), trigger: "change" }
                ],
                product_areas: [
                    { required: true, message: this.$t("Please input Product Area"), trigger: "change" }
                ],
                time_period: [
                    { validator: validateDateTime, message: this.$t("Please select Time Period"), trigger: "change" }
                ],
                comparison_time_period: [
                    { validator: validateDateTime, message: this.$t("Please select Compared Time Period"), trigger: "change" }
                ]
            }
        };
    },

    methods: {
        handleConfirmProductArea(event) {
            this.createCustomReportForm.product_areas = event;
            this.dialogProductAreaVisible = false;
        },
        handleConfirmCountries(event) {
            this.createCustomReportForm.countries = event;
            this.dialogCountriesVisible = false;
        },
        handleConfirmBanks(event) {
            this.createCustomReportForm.bank_ids = event;
            this.dialogBanksVisible = false;
        },
        getFilteredCountries(bankName) {
            if (this.banks.length) {
                return this.banks.filter(bank => bank.name === bankName);
            }
            return [];
        },
        generateReport() {
            this.showSuccessMessage = false;
            this.$refs.createCustomReportFormRef.validate(valid => {
                if (valid) {
                    this.buttonIndicator = "on";
                    const payload = {
                        ...this.createCustomReportForm,
                        bank_ids: [this.createCustomReportForm.bank_ids],
                        time_period: [
                            moment(this.createCustomReportForm.time_period.start_date).format('MM/DD/YYYY'),
                            moment(this.createCustomReportForm.time_period.end_date).format('MM/DD/YYYY'),
                        ],
                        comparison_time_period: [
                            moment(this.createCustomReportForm.comparison_time_period.start_date).format('MM/DD/YYYY'),
                            moment(this.createCustomReportForm.comparison_time_period.end_date).format('MM/DD/YYYY'),
                        ]
                    }

                    store.dispatch("generateReport", payload)
                        .then((res) => {
                            if (res.status === 200) {
                                this.showSuccessMessage = true;
                                this.$nextTick(() => {
                                    window.scrollTo({
                                        top: document.body.scrollHeight || document.documentElement.scrollHeight,
                                        behavior: "smooth"
                                    });
                                });
                            }
                        })
                        .catch((err) => {
                            this.showSuccessMessage = false;
                        })
                        .finally(() => {
                            this.buttonIndicator = "off";
                        });
                }
            });
        },
        disabledDate(time) {
            return time.getTime() > Date.now() || time.getTime() < new Date(this.businessCreateDate);
        }
    },

    mounted() {
        setCurrentPageBreadcrumbs({
            title: this.$t("Custom Reports")
            // subTitle: this.$t('Please define the content of your Report'),
        });
    },

    created() {
        this.$nextTick(() => {
            this.createCustomReportForm.bank_ids = (this.banks && this.banks.length) ? this.banks[0].id : null;
            this.createCustomReportForm.product_areas = this.product_areas;
            this.createCustomReportForm.countries = this.countries;
            this.createCustomReportForm.time_period = {
                start_date: new Date(new Date().getFullYear(), 0, 1),
                end_date: new Date()
            }
            this.createCustomReportForm.comparison_time_period = {
                start_date: new Date(new Date().getFullYear() - 1, 0, 1),
                end_date: new Date(new Date().getFullYear() - 1, 11, 31)
            };
            this.pageLoading = false;
        });
    }
};
</script>
<style lang="scss">
.arriving-soon-card {
    padding: 16px 30px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    display: none;

    &.show-arriving {
        display: flex;
    }

    &__img {
        display: flex;
        align-items: center;

        span {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.24);
            margin-left: 9px;
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__text {
        margin-left: 24px;
        padding-left: 22px;
        border-left: 1px solid #DDDDDD;

        h4 {
            margin-bottom: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #434343;
        }

        p {
            margin-bottom: 0;
            font-weight: 300;
            font-size: 14px;
            line-height: 23px;
            color: #434343;

            b {
                color: #435BF4;
            }
        }
    }

    &__hide {
        button {
            background: transparent;
            padding: 0;
            border: none;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #E22D21;
        }
    }
}

.checkboxes-wrapper {
    &__item {
        display: flex;
        gap: 60px;
        &--col {
            width: calc(50% - 30px);
        }
        @media (max-width: 768px) {
            flex-direction: column;
            &--col {
                width: 100%;
            }
        }
    }
}

.coming-soon {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

@media (max-width: 1200px) {
    .arriving-soon-card {
        &__content {
            flex-direction: column;
            align-items: center;
        }

        &__text {
            margin-left: 0;
            padding-left: 0;
            border-left: none;
        }

        &__img {
            margin-bottom: 20px;
            padding-bottom: 24px;
            border-bottom: 1px solid #DDDDDD;
        }
    }
}
</style>
